import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

window.protocolo = "https://";
window.protocoloNoSSL = "https://";
//window.subdominio = "masymascarne";
//window.rutasubdominio = "masymascarne.greencardpv.com";
window.subdominio = window.location.host.split('.')[0];
window.rutasubdominio = window.location.host;
let params = new URLSearchParams(window.location.search);
window.mesa = params.get('mesa');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();