import React, { useState, useEffect } from 'react'
import './modalMenu.css'
import { connect } from 'react-redux'
import { ADDPRODUCTO } from '../redux/actions/ProductosActions'
import ModalOpciones from './menuopciones'
function ModalMenu(props) {
    const [productoOpciones, setProductoOpciones] = useState([])
    const [total, settotal] = useState(0)
    useEffect(() => {
        settotal(props.productos.reduce((a, c) => a + c.precio * c.cantidad, 0))
    }, [props.productos])
    const eliminarProducto = (producto) => {
        props.ADDPRODUCTO(props.productos.filter((x) => x.id !== producto))
    }
    const handleProducto = (event, producto) => {
        const exist = props.productos.find((x) => x.id === producto.id)
        if (exist) {
            props.ADDPRODUCTO(props.productos.map((x) => x.id === producto.id ? { ...exist, cantidad: parseFloat(event.target.value) } : x))
        }
    }
    const handleOpciones = (producto) =>{
        document.getElementById('menuOpcioneswrapper').classList.toggle('hide')
        setProductoOpciones(producto)
    }
    const backOpciones = () =>{
        document.getElementById('menuOpcioneswrapper').classList.toggle('hide')
    }
    const handleOrdenar = () =>{
        let orden = ''
        props.productos.map((x)=>{
            console.log(x);
            var unidad_medida="";
            var cantidadFormat="";
            if(x.unidad_medida==2){
                unidad_medida="kg";
            }
            if (x.extra) {
                if(x.unidad_medida===2 && x.cantidad % 1 == 0){
                    orden = orden.concat('%20'+x.cantidad+'%20'+unidad_medida+'%20-'+'%20'+x.titulo.replace(/ /g,"%20")+'%20(%20'+x.extra.replace(/ /g+'%20')+')'+'%0A')
                }else if(x.unidad_medida===1 && x.cantidad % 1 == 0){
                    orden = orden.concat('%20'+x.cantidad+'%20-%20'+x.titulo.replace(/ /g,"%20")+'%20(%20'+x.extra.replace(/ /g+'%20')+')'+'%0A')
                }else{
                    cantidadFormat=x.cantidad*1000;
                    unidad_medida="gr";
                    orden = orden.concat('%20'+cantidadFormat+'%20'+unidad_medida+'%20-'+'%20'+x.titulo.replace(/ /g,"%20")+'%20(%20'+x.extra.replace(/ /g+'%20')+')'+'%0A')
                }
            }else{
                if(x.unidad_medida==2 && x.cantidad % 1 == 0){
                    orden = orden.concat('%20'+x.cantidad+'%20'+unidad_medida+'%20-'+'%20'+x.titulo.replace(/ /g,"%20")+'%0A')
                }else if(x.unidad_medida==1 && x.cantidad % 1 == 0){
                    orden = orden.concat('%20'+x.cantidad+'%20-%20'+x.titulo.replace(/ /g,"%20")+'%0A')
                }else{
                    cantidadFormat=x.cantidad*1000;
                    unidad_medida="gr";
                    orden = orden.concat('%20'+cantidadFormat+'%20'+unidad_medida+'%20-'+'%20'+x.titulo.replace(/ /g,"%20")+'%0A')
                }
            }
            
        })
        const texto = '*¡Hola!*%0A*Quiero Realizar El Siguiente Pedido:*%0A';
        var mesa = window.mesa ? '*Mesa%20'+window.mesa+'*%0A' : '';
        window.location.href=`https://api.whatsapp.com/send?phone=${props.data.Whatsapp}&text=${texto.replace(/ /g,'%20')}${mesa}${orden}%0A*Total:%20$${numberWithCommas(total)}*`
    }
    const numberWithCommas = (x) =>
    {
        console.log(x);
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(",");
    }
    if (props.productos.length === 0 && props.estatus === true) {
        return (
            <div className="MenuModal">
                <div className="containerMenuModal">
                    <div className="agregarproductos">
                        Agrega productos al carrito
                    </div>
                </div>
                <div className="coverMenuModal" onClick={() => props.setmodalMenu(false)}></div>
            </div>
        )
    }
    if (props.estatus === true) {
        return (
            <>
                <div className="MenuModal">
                    <div className="containerMenuModal">
                        <div className="titutloMenuModal">
                            Tu Orden:
                        </div>
                        <div className="notaMenuModal">
                        Nuestros precios no incluyen IVA....si requiere factura será adicional el 16%
                        </div>
                        <div className="ProdutosMenuModal">
                            {props.productos.map(producto => {
                                console.log(producto)
                                return (
                                    <div className="productoModalMenu">
                                        <input type="text" value={producto.cantidad} onChange={(e) => handleProducto(e, producto)} readOnly />
                                        <div className="ButtonMenuModal" onClick={()=>handleOpciones(producto)}>{producto.titulo}</div>
                                        <div className="ButtonMenuModal"  onClick={()=>handleOpciones(producto)} ><strong>${(producto.precio) * (producto.cantidad)}</strong></div>
                                        <div className="containerDelete">
                                            <div className="deleteMenu" onClick={() => eliminarProducto(producto.id)}><i className="fas fa-trash"></i></div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className="divButton">
                            <div className="ordenarBoton" onClick={()=>handleOrdenar()}>Ordenar - ${total}</div>
                        </div>
                    </div>
                    <div className="coverMenuModal" onClick={() => props.setmodalMenu(false)}></div>
                </div>
                <ModalOpciones handleOpciones={backOpciones} producto={productoOpciones}/>
            </>
        )
    }

    else {
        return (
            null
        )
    }
}


const PropsStore = state => {
    return {
        data: state.dataPlantilla.data,
        productos: state.Productos.productos
    }
}
const functionStore = {
    ADDPRODUCTO,
}

export default connect(PropsStore, functionStore)(ModalMenu)
